import { Playback } from '@iheartradio/web.playback';
import { flush, isUndefined } from '@iheartradio/web.utilities';

import { playback } from '../../playback';

type UseIsCurrentProps = Omit<Playback.Station, 'targeting'>;

export function useIsCurrentStation(props: UseIsCurrentProps) {
  const stationToLoad = flush<UseIsCurrentProps>(props) as Playback.Station;

  const currentState = playback.useState();

  if (isUndefined(currentState.station)) {
    return true;
  }

  if (
    currentState.station?.type !== stationToLoad.type ||
    currentState.station?.id !== stationToLoad.id
  ) {
    return false;
  }

  switch (stationToLoad.type) {
    case Playback.StationType.Album:
    case Playback.StationType.Playlist:
    case Playback.StationType.Podcast:
    case Playback.StationType.TopSongs: {
      if (isUndefined(stationToLoad.seed)) {
        return true;
      }

      return currentState.queue[currentState.index].id === stationToLoad.seed;
    }
    case Playback.StationType.Artist:
    case Playback.StationType.Favorites:
    case Playback.StationType.Live:
    case Playback.StationType.PlaylistRadio: {
      return true;
    }
    default: {
      return false;
    }
  }
}
