import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { type OptionalPlayProps, Play } from './play';
import { type UseArtistPlayProps } from './use-artist-play';

export type ArtistPlayProps = Omit<UseArtistPlayProps, 'targeting'> &
  OptionalPlayProps;

export function ArtistPlay({
  color = 'white',
  size = 40,
  seed,
  ...rest
}: ArtistPlayProps) {
  const isPremium = isPremiumUser(useUser());

  const props = {
    ...rest,
    seed: isPremium ? seed : undefined,
  };

  return (
    <Play
      color={color}
      size={size}
      type={Playback.StationType.Artist}
      {...props}
    />
  );
}
