import { useToast } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { useCallback } from 'react';

import { UpgradeCTANotification } from '~app/components/cta/upgrade-cta';
import { AdsTargetingState, useTargetingReady } from '~app/contexts/ads';
import { useUser } from '~app/contexts/user';
import { playback } from '~app/playback/playback';
import { isPremiumUser } from '~app/utilities/user';

import { usePlay } from './use-play';

export type UseAlbumPlayProps = (
  | Playback.AlbumStation
  | Playback.ArtistStation
) & { artistId: number };

export function useAlbumPlay(
  props: Omit<UseAlbumPlayProps, 'type' | 'targeting'>,
) {
  const isPremium = isPremiumUser(useUser());
  const player = playback.usePlayer<UseAlbumPlayProps>();
  const toast = useToast();

  const checkReadyToLoad = useTargetingReady();

  const doLoadAndPlay = useCallback(async () => {
    await player.load({
      ...props,
      id: props.artistId,
      type: Playback.StationType.Artist,
      targeting: AdsTargetingState.get('targetingParams'),
    });
    await player.play();
  }, [player, props]);

  const play = usePlay({
    ...props,
    type: Playback.StationType.Album,
  });

  async function onClick() {
    if (isPremium) {
      play.onClick();
      return;
    }

    toast(dismiss => <UpgradeCTANotification dismiss={dismiss} />);

    if (checkReadyToLoad()) {
      await doLoadAndPlay();
    } else {
      (async function doCheck() {
        globalThis.window.setTimeout(() => {
          if (checkReadyToLoad()) {
            doLoadAndPlay();
          } else {
            doCheck();
          }
        }, 100);
      })();
    }
  }

  return { ...play, onClick } as const;
}
