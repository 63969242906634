import {
  type IconProps,
  Button,
  Flex,
  PauseIcon,
  Player,
  PlayIcon,
  Popover,
  StopIcon,
  Text,
} from '@iheartradio/web.companion';
import { type Playback } from '@iheartradio/web.playback';
import { Link as RouterLink } from '@remix-run/react';
import { type ComponentProps } from 'react';

import { useConfig } from '~app/contexts/config';

import { usePlay } from './use-play';

export type OptionalPlayProps = {
  color?: ComponentProps<typeof Player.Play>['color'];
  css?: ComponentProps<typeof Player.Play>['css'];
  size?: IconProps['size'];
  isDisabled?: boolean;
};

export type PlayProps = Omit<Playback.Station, 'targeting'> & OptionalPlayProps;

export function Play({
  color,
  size = 40,
  css,
  isDisabled = false,
  ...props
}: PlayProps) {
  const {
    adBreak,
    buffering,
    disabled,
    isCurrent,
    playing,
    onClick,
    stoppable,
    tooltip,
  } = usePlay(props);
  const config = useConfig();

  return adBreak && !isCurrent ? (
    <Popover
      trigger={
        <Player.Play
          css={{
            dark: {
              backgroundColor: '$gray-500',
              color: '$gray-400',
            },
            light: {
              backgroundColor: '$gray-200',
              color: '$gray-300',
            },
          }}
          data-test="player-play-button-with-ad-break-popover"
          disabled={isDisabled ?? false}
          loading={buffering}
          tooltip={tooltip}
        >
          <PlayIcon size={size} />
        </Player.Play>
      }
    >
      <Flex direction="column" gap="$16">
        <Text kind="h5">Start listening after the ad</Text>
        <Text kind="body-4">Want to listen to anything, anytime?</Text>
        <Button
          as={RouterLink}
          color="red"
          css={{
            fontSize: '$14',
          }}
          kind="primary"
          size="large"
          target="_blank"
          to={`${config.urls.account}/subscribe`}
        >
          Listen with iHeart All Access
        </Button>
      </Flex>
    </Popover>
  ) : (
    <Player.Play
      color={color}
      css={css}
      data-test="player-play-button"
      disabled={isDisabled ?? disabled}
      loading={buffering}
      onClick={onClick}
      tooltip={tooltip}
    >
      {!playing && <PlayIcon size={size} />}
      {(!stoppable && playing) || (playing && adBreak) ? (
        <PauseIcon size={size} />
      ) : null}
      {stoppable && playing && !adBreak ? <StopIcon size={size} /> : null}
    </Player.Play>
  );
}
