import { Playback } from '@iheartradio/web.playback';

import { type OptionalPlayProps, Play } from './play';
import { type UseFavoritesPlayProps } from './use-favorites-play';

export type FavoritesPlayProps = UseFavoritesPlayProps & OptionalPlayProps;

export function FavoritesPlay({
  color = 'white',
  size = 40,
  ...rest
}: FavoritesPlayProps) {
  return (
    <Play
      color={color}
      size={size}
      type={Playback.StationType.Favorites}
      {...rest}
    />
  );
}
