import { Player, PlayIcon } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { type OptionalPlayProps, Play } from './play';
import {
  type UseTopSongsPlayProps,
  useTopSongsPlay,
} from './use-top-songs-play';

export type TopSongsPlayProps = UseTopSongsPlayProps & OptionalPlayProps;

export function TopSongsPlay({
  color = 'white',
  size = 40,
  seed,
  ...props
}: TopSongsPlayProps) {
  const isPremium = isPremiumUser(useUser());
  const play = useTopSongsPlay(props);

  return isPremium ? (
    <Play
      color={color}
      seed={seed}
      size={size}
      type={Playback.StationType.TopSongs}
      {...props}
    />
  ) : (
    <Player.Play color={color} onClick={play.onClick}>
      <PlayIcon size={size} />
    </Player.Play>
  );
}
